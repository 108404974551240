import { useCallback, useEffect, useState } from 'react'
import { useClientEnvironmentVariables } from './useClientEnvironmentVariables'

const MESSAGE_NAME = 'healthcheck'

export const useExtensionStatus = () => {
  const { CONSTANTS } = useClientEnvironmentVariables()

  const [isActive, setIsActive] = useState(false)

  const [state, setState] = useState<'loading' | 'idle'>('loading')

  const checkExtensionStatus = useCallback(async () => {
    setState('loading')

    try {
      const response = await chrome.runtime.sendMessage(
        CONSTANTS.CHROME_EXTENSION_ID,
        { type: MESSAGE_NAME }
      )
      updateExtensionStatus(!!response)
    } catch (_e) {
      updateExtensionStatus(false)
    }
  }, [CONSTANTS.CHROME_EXTENSION_ID])

  const updateExtensionStatus = (isActive: boolean) => {
    setTimeout(() => {
      setIsActive(isActive)
      setState('idle')
    }, 500)
  }
  useEffect(() => {
    checkExtensionStatus()
  }, [checkExtensionStatus])

  return {
    isActive,
    state,
    checkStatus: checkExtensionStatus
  }
}
